import React, { useEffect, useState } from 'react';
import { getEventsWithCarsForDate } from '../data/dataFunctions';
import Button from '@mui/material/Button';
import './DnevniPlan.css';
import { theme_color } from '../data/values'; // Assuming you have this for consistent color

export default function DnevniPlan() {
    const [pocetakEvents, setPocetakEvents] = useState([]);
    const [krajEvents, setKrajEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [activeFilters, setActiveFilters] = useState([]); // State to hold active filters
    const [rentalTypes, setRentalTypes] = useState([]); // State for available rental types

    useEffect(() => {
        async function fetchEventsForSelectedDate() {
            const { pocetakEvents, krajEvents } = await getEventsWithCarsForDate(selectedDate);

            const sortedPocetak = pocetakEvents.sort((a, b) => a.car.sifraNajma.localeCompare(b.car.sifraNajma));
            const sortedKraj = krajEvents.sort((a, b) => a.car.sifraNajma.localeCompare(b.car.sifraNajma));

            setPocetakEvents(sortedPocetak);
            setKrajEvents(sortedKraj);
        }
        fetchEventsForSelectedDate();
    }, [selectedDate]);

    useEffect(() => {
        // Fetch available rental types
        async function fetchRentalTypes() {
            const fetchedTypes = ['Automobil', 'Skuter', 'Smještaj']; // Example types
            setRentalTypes(fetchedTypes);
        }
        fetchRentalTypes();
    }, []);

    const toggleFilter = (rentalType) => {
        if (activeFilters.includes(rentalType)) {
            setActiveFilters(activeFilters.filter(type => type !== rentalType));
        } else {
            setActiveFilters([...activeFilters, rentalType]);
        }
    };

    const handleDateChange = (event) => {
        setSelectedDate(new Date(event.target.value));
    };

    // Filter events based on active filters (case-insensitive)
    const filteredPocetakEvents = activeFilters.length === 0 
        ? pocetakEvents 
        : pocetakEvents.filter(event => {
            if (event.car.vrsta) {
                return activeFilters.some(filter => filter.toLowerCase() === event.car.vrsta.toLowerCase());
            }
            return false;
        });

    const filteredKrajEvents = activeFilters.length === 0 
        ? krajEvents 
        : krajEvents.filter(event => {
            if (event.car.vrsta) {
                return activeFilters.some(filter => filter.toLowerCase() === event.car.vrsta.toLowerCase());
            }
            return false;
        });

    const handlePrint = () => {
        window.print();
    };

    return (
        <div style={{ padding: '20px' }}>
            <div className="date-picker-filter-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="date-picker-container" style={{ marginRight: '20px' }}>
                    <label>Odaberite datum:</label>
                    <input 
                        type="date" 
                        onChange={handleDateChange} 
                        value={selectedDate.toISOString().substr(0, 10)} 
                        style={{
                            padding: '10px',
                            border: '1px solid gray',
                            borderRadius: '4px'
                        }}
                    />
                </div>

                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        onClick={handlePrint}
                        sx={{
                            backgroundColor: theme_color,
                            '&:hover': {
                                backgroundColor: theme_color,
                                opacity: 0.9
                            }
                        }}
                    >
                        Ispiši dnevni plan
                    </Button>

                    <div className="filter-buttons" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {rentalTypes.map((rentalType, index) => (
                            <Button
                            key={index}
                            variant="outlined"
                            onClick={() => toggleFilter(rentalType)}
                            sx={{
                                borderWidth: "2px",
                                borderStyle: "solid",
                                borderColor: activeFilters.includes(rentalType) ? theme_color : 'gray',
                                backgroundColor: activeFilters.includes(rentalType) ? theme_color : 'transparent',
                                color: activeFilters.includes(rentalType) ? '#fff' : '#000',
                                '&:hover': {
                                    backgroundColor: activeFilters.includes(rentalType) ? theme_color : 'gray',
                                    borderColor: activeFilters.includes(rentalType) ? theme_color : 'gray',
                                    color: '#fff'
                                }
                            }}
                        >
                            {rentalType}
                        </Button>
                    
                    ))}
                    </div>
                </div>
            </div>

            <div className="dnevni-plan-wrapper">
                <div className="pocetak-wrapper">
                    <h2 className="naslovi">POČETAK</h2>
                    {filteredPocetakEvents.length === 0 ? (
                        <p>Nema događaja koji počinju na odabrani datum.</p>
                    ) : (
                        filteredPocetakEvents.map((event, index) => (
                            <div key={index} className="pocetak-card">
                                <div>
                                    <p><strong>Vozilo:</strong> {event.car.text ? `${event.car.text} | ${event.car.desc}` : 'Nepoznato vozilo'}</p>
                                    <p><strong>Kupac:</strong> {event.Subject}</p>
                                    <p><strong>Lokacija prikupa:</strong> {event.pocetak}</p>
                                    <p><strong>Polog:</strong> {event.polog}</p>
                                    <p><strong>Račun:</strong> {event.brojPonude}</p>
                                    <p><strong>Bilješka:</strong> {event.brojPonude}</p>
                                </div>
                                {event.car.imgLink && (
                                    <img src={event.car.imgLink} alt="Vozilo" className="car-image" />
                                )}
                            </div>
                        ))
                    )}
                </div>

                <div className="vertical-line"></div>

                <div className="kraj-wrapper">
                    <h2 className="naslovi">KRAJ</h2>
                    {filteredKrajEvents.length === 0 ? (
                        <p>Nema događaja koji završavaju na odabrani datum.</p>
                    ) : (
                        filteredKrajEvents.map((event, index) => (
                            <div key={index} className="kraj-card">
                                <div>
                                    <p><strong>Vozilo:</strong> {event.car.text ? `${event.car.text} | ${event.car.desc}` : 'Nepoznato vozilo'}</p>
                                    <p><strong>Kupac:</strong> {event.Subject}</p>
                                    <p><strong>Lokacija povrata:</strong> {event.kraj}</p>
                                    <p><strong>Polog:</strong> {event.polog}</p>
                                    <p><strong>Račun:</strong> {event.brojPonude}</p>
                                    <p><strong>Bilješka:</strong> {event.brojPonude}</p>
                                </div>
                                {event.car.imgLink && (
                                    <img src={event.car.imgLink} alt="Vozilo" className="car-image" />
                                )}
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}
